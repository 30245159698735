import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import {graphql, navigate} from "gatsby";
import * as classes from '../assets/pageStyles/404.module.scss';
import {useTranslation} from "gatsby-plugin-react-i18next";
import {StaticImage} from "gatsby-plugin-image";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const NotFoundPage = () => {
    const {t}=useTranslation();

    return (
        <Layout location="/404">
            <main className={classes.container}>
                <div className={classes.contentContainer}>
                    <div className={classes.imageContainer}>
                        <StaticImage className={classes.image} src={"../../src/images/404.svg"} alt="Logo" objectFit="contain" placeholder="tracedSVG"/>
                    </div>
                    <div className={classes.message}>
                        <span>{t('notFoundPage')}</span>
                    </div>
                    <div className={classes.returnTextContainer}>
                        <button className={classes.buttonGoBack} onClick={(e) => navigate("/")}>{t('returnToIndex')}</button>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
